<template>
  <div class="px-3">
    <v-overlay v-model="loadAccount">
      <v-progress-circular
        indeterminate
        color="primary"
        size="40"
      ></v-progress-circular>
    </v-overlay>
    <div style="position: absolute; top: 5px; margin-left: -8px">
      <v-btn class="capitalize" @click="openDialog" rounded color="primary">
        Create Agency/Reseller Account
      </v-btn>
    </div>
    <div class="mt-6 mb-n3 ml-n3 d-flex">
      <v-text-field
        rounded
        v-model="search"
        dense
        solor
        class="white"
        style="max-width: 400px; height: 38px"
        placeholder="Search by name or email"
        @keyup="searchUser"
      >
        <template v-slot:append>
          <v-icon class="secondary rounded-xl mt-1 pa-1 mr-n5"
            >mdi-magnify</v-icon
          >
        </template>
      </v-text-field>
      <div style="margin: auto 7px 5px">
        {{ account_added + " of " + account_total }}
      </div>
    </div>
    <v-row class="mt-6 white rounded-xl px-5 font-weight-bold mb-2 text-left">
      <v-col cols="4">
        <div>Name</div>
      </v-col>
      <v-col cols="2">
        <div>Email</div>
      </v-col>
      <v-col cols="2">
        <div>Minutes Used</div>
      </v-col>
      <v-col cols="2">
        <div>Date Added</div>
      </v-col>
      <v-col cols="1">
        <div>Plan</div>
      </v-col>
      <v-col cols="1">
        <div>Action</div>
      </v-col>
    </v-row>
    <div class="mt-6" v-for="(account, index) in accounts" :key="index">
      <v-row
        class="white rounded-xl px-5 font-weight-bold mb-2 text-left capitalize text-12"
      >
        <v-col cols="4" align-self="center">
          <a class="text-decoration-none black--text">
            {{ account.account.name }}
          </a>
        </v-col>
        <v-col cols="2" align-self="center">
          <div style="text-transform: lowercase">
            {{ account.account.email }}
          </div>
        </v-col>
        <v-col cols="2" align-self="center">
          <div>{{ account.minutes_used }}</div>
        </v-col>
        <v-col cols="2" align-self="center">
          <div>
            {{ new Date(account["created_at"]).toDateString() }}
          </div>
        </v-col>
        <v-col cols="1" align-self="center">
          {{ account.account.account_type }}
        </v-col>
        <v-col cols="1" align-self="center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="openDialog('edit_user', account)">
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="deleteAccount(account)">
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <div class="pt-4" v-if="!accounts.length">No record found!</div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card>
        <v-toolbar color="primary" flat>
          <div class="capitalize white--text">
            {{ edit_user ? "Edit User Account" : "add new account" }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon size="20" color="white">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-5 pb-2">
          <v-form lazy-validation ref="newAccount">
            <v-text-field
              rounded
              dense
              flat
              solo
              background-color="secondary"
              type="text"
              v-model="form.account_name"
              placeholder="Enter account name"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <v-text-field
              rounded
              dense
              flat
              solo
              background-color="secondary"
              type="email"
              v-model="form.account_email"
              placeholder="Enter account email address"
              :rules="[(v) => !!v || 'This field is required']"
            ></v-text-field>
            <v-text-field
              rounded
              dense
              flat
              solo
              background-color="secondary"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form.password"
              autocomplete="new-password"
              placeholder="Enter account password"
              :rules="
                !edit_user ? [(v) => !!v || 'This field is required'] : []
              "
            >
              <template v-slot:append>
                <v-icon
                  @click="showPassword = !showPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <div style="margin: 0 20%">
              <label class="float-start">
                {{ edit_user ? "Change Plan" : "Add Plan" }}
              </label>
              <v-autocomplete
                v-model="form.account_type"
                :items="upgrades"
                item-text="name"
                item-value="id"
                solo
                dense
                flat
                rounded
                background-color="secondary"
                style="max-width: 200px"
              >
                <template v-slot:append>
                  <v-icon class="rounded-xl white">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </div>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="capitalize primary"
              rounded
              :loading="loadNewAccount"
              @click="
                $refs.newAccount.validate()
                  ? !edit_user
                    ? addAccount()
                    : editAccount()
                  : null
              "
            >
              {{ buttonText }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-pagination
      v-model="pagination.page"
      :length="pagination.total"
      :total-visible="7"
      @input="nextPage()"
      v-if="accounts.length && pagination.total > 1"
    ></v-pagination>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions } from "vuex";
export default {
  name: "AddedAccounts",
  data() {
    return {
      headers: [],
      accounts: [],
      loadAccount: false,
      toast: new ResponseHelper(),
      dialog: false,
      loadNewAccount: false,
      form: {
        account_name: "",
        account_email: "",
      },
      showPassword: false,
      edit_user: false,
      buttonText: "Add Account",
      search: "",
      loadUpgrades: false,
      upgrades: [],
      timerId: null,
      timer: 0,
      pagination: {
        page: 1,
        total: 1,
      },
      account_added: 0,
      account_total: 0,
    };
  },
  created() {
    this.getAccounts();
    this.getUpgrades();
  },
  methods: {
    ...mapActions({
      add_account: "settings/add_account",
      edit_account: "settings/edit_account",
    }),
    async getAccounts(page) {
      this.loadAccount = true;
      if (!page) {
        page = "?page=" + this.pagination.page + "&search=" + this.search;
      }
      try {
        const response = await this.$axios.get("/accounts" + page);
        this.accounts = response.data.accounts.data.filter((x) => x.account);
        this.pagination.page = response.data.accounts["current_page"];
        this.pagination.total = response.data.accounts["last_page"];
        this.account_total = response.data.account_total;
        this.account_added = response.data.accounts_added;
        this.loadAccount = false;
      } catch (e) {
        this.loadAccount = false;
        this.toast.sendError(e);
      }
    },
    async deleteAccount(item) {
      let confirmDelete = confirm("This action is not reversible...");
      if (confirmDelete) {
        this.loadAccount = true;
        try {
          const response = await this.$axios.delete(
            "/accounts/" + item.id + "/delete"
          );
          this.getAccounts();
          this.$store.commit("auth/USER_DATA", response.data, { root: true });
          this.loadAccount = false;
          this.toast.sendSuccess(response);
        } catch (e) {
          this.loadAccount = false;
          this.toast.sendError(e);
        }
      }
    },
    openDialog(value = null, account) {
      this.buttonText = "Add Account";
      if (this.form.account_email && !value) {
        this.form.account_email = null;
      }
      if (value === "edit_user") {
        this.form.user_id = account.account_id;
        this.form.account_name = account.account.name;
        this.form.account_email = account.account.email;
        this.form.password = null;
        this.buttonText = "Save Changes";
        this.getCurrentPlan(account);
        this.edit_user = true;
      }
      setTimeout(() => {
        this.dialog = true;
      }, 500);
    },
    closeDialog() {
      this.edit_user = false;
      this.dialog = false;
      this.$refs.newAccount.reset();
    },
    async addAccount() {
      this.loadNewAccount = true;
      this.form.add_type = "from_user";
      try {
        const response = await this.add_account(this.form);
        this.getAccounts();
        this.loadNewAccount = false;
        this.closeDialog();
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loadNewAccount = false;
        this.toast.sendError(e);
      }
    },
    async editAccount() {
      this.loadNewAccount = true;
      try {
        const response = await this.edit_account(this.form);
        this.closeDialog();
        this.toast.sendSuccess(response);
        this.edit_user = false;
        this.getAccounts();
        this.loadNewAccount = false;
      } catch (e) {
        this.closeDialog();
        this.edit_user = false;
        this.loadNewAccount = false;
        this.toast.sendError(e);
      }
    },
    async searchUser() {
      if (this.search.length > 0 || this.search === "") {
        this.timer = 1;
        this.timerId = setTimeout(() => {
          if (this.timer > 0) {
            this.pagination.page = 1;
            this.getAccounts();
          }
          this.timer = 0;
        }, 2000);
      }
    },
    async getUpgrades() {
      this.loadUpgrades = true;
      try {
        const response = await this.$axios.get("/upgrades");
        this.upgrades = response.data;
        this.upgrades.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        this.loadUpgrades = false;
      } catch (e) {
        this.loadUpgrades = false;
        this.toast.sendError(e);
      }
    },
    getCurrentPlan(account) {
      const plan = this.upgrades.find(
        (x) => x.name === account.account.account_type
      );
      if (typeof plan !== "undefined") {
        this.form.account_type = plan.id;
      }
    },
    nextPage() {
      let page = "?page=" + this.pagination.page + "&search=" + this.search;
      this.getAccounts(page);
    },
  },
};
</script>

<style scoped></style>
